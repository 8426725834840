<template>
  <div class="friend">
    <!-- <div class="title">
      <img src="@/assets/bracelet_img/7.png"
           mode="">
      <span>好友</span>
    </div>
    <div class="blackBar">
      <div class="greenBar mar_10"></div>
      <span class="mar_10">一</span>
      <span class="mar_10">起</span>
      <span class="mar_10">社</span>
      <span class="mar_10">交</span>
      <span class="mar_10">赚</span>
      <span>钱</span>
    </div> -->
    <watch-head :title="$t('好友')"
                :img="require('@/assets/bracelet_img/7.png')"></watch-head>
    <div class="content">
      <div class="recommend">
        <span :class="{'vn-bold-italic': $i18n.locale == 'vn'}">{{$t('朋友')}}: {{ totalFriends }}</span>
        <img @click="goTo('/friendAdd')"
             src="@/assets/bracelet_img/1.png"
             alt="">
      </div>
      <van-list v-model="loading"
                :class="{'vn-italic': $i18n.locale == 'vn'}"
                :finished="finished"
                :finished-text="$t('没有更多了')"
                :loading-text="$t('加载中...')"
                :immediate-check="false"
                :offset="20"
                @load="onLoad"
                class="list">
        <div class="item"
             v-for="(item, index) in list"
             :key="index">
          <div class="right">
            <div class="img">
              <img :src="item.avatar"
                   alt="">
              <div class="text">{{ item.level_no }}</div>
            </div>
            <div class="info">
              <div class="name"
                   :class="{'vn-italic': $i18n.locale == 'vn'}">{{ item.nickname ? item.nickname : $t('暂无昵称') }}</div>
              <div class="Ranking"
                   :class="{'vn-italic': $i18n.locale == 'vn'}">{{$t('排行')}} : {{ item.ionz_ranking == 0 ? '100+' : item.ionz_ranking }}</div>
            </div>
          </div>
          <div class="left">
            <img class="mar_right"
                 src="@/assets/bracelet_img/11.png"
                 alt="">
            <img class="mar_right"
                 src="@/assets/bracelet_img/12.png"
                 alt="">
            <img src="@/assets/bracelet_img/13.png"
                 @click="goTo('/message', item.id)"
                 alt="">
          </div>
        </div>
      </van-list>
    </div>
    <div class="btm">
      <!-- <div class="refresh">刷新</div> -->
      <div class="request_add">
        <div class="left">
          <div @click="gotoUnityweb"
               class="box">
            <img src="@/assets/bracelet_img/5.png"
                 alt="">
          </div>
        </div>
        <!-- <div class="right">
          
          <span class="mar_ri_1">M</span>
          <span class="mar_ri_1">E</span>
          <span class="mar_ri_1">T</span>
          <span class="mar_ri_1">A</span>
          <span class="mar_ri_1">N</span>
          <span class="mar_ri_1">E</span>
          <span class="mar_ri_1">B</span>
          <span class="mar_ri_1">U</span>
          <span class="mar_ri_1">L</span>
          <span class="mar_ri_1">A</span>
          <span>S</span>
        </div> -->
        <div class="right">
          <van-badge class="request pointer"
                     :content="friendsnum > 0 ? friendsnum : '' "
                     max="99">
            <div @click="goToRequest" :class="{'vn-bold-italic': $i18n.locale == 'vn'}">{{$t('请求')}}</div>
          </van-badge>
          <div @click="goTo('/friendAdd')" class="add pointer" :class="{'vn-bold-italic': $i18n.locale == 'vn'}">{{$t('添加好友')}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import watchHead from '@/components/watchHead'
import { getFriendsList, FriendsnumApi } from '../../../api/api'
import { mapState } from 'vuex'
import { getFriendList, timIsLogin, tim } from '@/utils/tim.js'
export default {
  data () {
    return {
      totalFriends: 0,
      loading: false,
      finished: false,
      page: 1,
      list: [],
      timer: null,
      friendsnum: 0,
    }
  },
  components: {
    watchHead,
  },
  computed: {
    ...mapState([
      'web3',
    ]),
  },
  created () { this.waitForGlobal(); },
  methods: {
    getFriendList(){
      getFriendList().then(res => {
        console.log('im好友列表----',res);
      })
    },
    onLoad () {
      getFriendsList({
        page: this.page
      }).then(res => {
        if (res.code == 0) {
          this.list = this.list.concat(res.data.data)
          this.loading = false;
          this.totalFriends = res.count
          if (res.data.total <= this.page) {
            this.finished = true;
          }
          this.page++
        } else {
          this.finished = true;
        }
      })
    },
    goToRequest () {
      this.$router.push({
        path: "/friendRequest"
      })
    },
    goTo (url, fromUid) {
      this.$router.push({
        path: url,
        query: {
          fromUid
        }
      })
    },
    gotoUnityweb () {
      window.location.href = 'https://api.metanebulas.com/unityweb/'
    },
    /* 获取统计 */
    getFriendsnum () {
      FriendsnumApi().then(res => {
        if (res.code == 0) {
          this.friendsnum = res.data.friendsnum
        }
      })
    },
    //链接钱包
    waitForGlobal: function () {
      window.clearTimeout(this.timer);
      // 1. 刷新页面时是否已经连接钱包登录
      if (localStorage.getItem('Token') && this.web3 && localStorage.getItem('defaultAddress') && timIsLogin) {
        window.clearTimeout(this.timer);
        this.loading = true
        this.finished = false
        this.list = []
        this.page = 1
        this.onLoad();

        this.getFriendsnum();
        this.getFriendList();
      } else {
        // 如果检测到没有连接钱包登录
        this.timer = setTimeout(
          this.waitForGlobal
          , 100);
      }
    },
  }
}
</script>

<style lang="less" scoped>
// div{
// 	box-sizing: border-box;
// }
.friend {
  width: 100%;
  min-height: 100vh;
  padding-top: 75px;
  padding-bottom: 60px;
  background-image: url(~@/assets/bracelet_img/8.png);
  background-repeat: repeat-y;
  background-size: 100% 100%;
  color: #ffffff;
  //   .title {
  //     padding: 0 2vw;
  //     background-color: #6480f3;
  //     display: flex;
  //     align-items: center;
  //     font-family: '071-SSRuiFengTi';
  //     font-size: 46px;
  //     margin-bottom: 1vw;
  //     img {
  //       width: 9vw;
  //       height: 9vw;
  //       margin-right: 2vw;
  //     }
  //   }
  //   .blackBar {
  //     // padding: 2rpx;
  //     background-color: #2b2b2b;
  //     display: flex;
  //     align-items: center;
  //     font-family: 'TsangerYuYangT-W03';
  //     font-size: 3vw;
  //     .greenBar {
  //       width: 5vw;
  //       height: 3vw;
  //       background-color: #a7f264;
  //     }
  //     .mar_10 {
  //       margin-right: 1vw;
  //     }
  //   }
  .content {
    padding: 18px 15px 0;
    //     .search {
    //       padding: 0;
    //       border-radius: 1vw;
    //       margin-bottom: 7vw;
    //       .van-field {
    //         padding: 1vw 3vw 1vw 0;
    //       }
    //       /deep/.van-field__right-icon {
    //         display: flex;
    //         align-items: center;
    //       }
    //       .right-icon {
    //         width: 7vw;
    //         height: 7vw;
    //       }
    //     }
    .recommend {
      font-family: '071-SSRuiFengTi';
      font-size: 32px;
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      img {
        width: 28px;
        height: 28px;
        cursor: pointer;
      }
    }
    .list {
      height: 65vh;
      overflow-y: scroll;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE 10 */
      .item {
        width: 100%;
        //    height: 20vw;
        background-image: linear-gradient(
            237deg,
            rgba(118, 233, 214, 0.83) 0%,
            rgba(159, 174, 230, 0.83) 100%
          ),
          linear-gradient(#ffffff, #ffffff);
        background-blend-mode: normal, normal;
        border-radius: 7.5px;
        // opacity: 0.75;
        padding: 7.5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 3.75px;
        .right {
          max-width: 60%;
          display: flex;
          align-items: center;
          .img {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background-color: #2b2b2b;
            border: solid 3px #2b2b2b;
            position: relative;
            margin-right: 22px;
            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
            .text {
              width: 26px;
              height: 26px;
              line-height: 26px;
              background-color: #2b2b2b;
              border-radius: 50%;
              position: absolute;
              right: -14px;
              bottom: 0;
              text-align: center;
              font-family: 'AeroMaticsDisplayBoldItalic';
            }
          }
          .info {
            .name {
              font-family: 'AeroMaticsDisplayBoldItalic';
              font-size: 22px;
              width: 110px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .Ranking {
              font-family: 'AeroMaticsDisplayRegular';
              font-size: 16px;
            }
          }
        }
        .left {
          display: flex;
          align-items: center;
          img {
            width: 34px;
            height: 34px;
            border-radius: 50%;
            cursor: pointer;
          }
          .mar_right {
            margin-right: 12px;
          }
        }
      }
    }
    .list::-webkit-scrollbar {
      display: none;
    }
  }
  .btm {
    width: 100%;
    font-family: '071-SSRuiFengTi';
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    bottom: 8px;
    // .refresh{
    // 	padding: 1vw 20vw;
    // 	background-color: #8ff9f6;
    // 	border-radius: 4vw 1vw 4vw 1vw;
    // 	border: 1vw solid #261E36;
    // 	font-size: 8vw;
    // 	color: #2b2b2b;
    // 	margin-bottom: 6vw;
    // }
    .request_add {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        width: 64px;
        height: 52px;
        background-color: rgba(43, 43, 43, 0.5);
        border-radius: 0 0 34px 0;
        // opacity: 0.5;
        padding: 8px 8px 8px 0;
        .box {
          background-color: #2b2b2b;
          border-radius: 0px 0px 30px 0px;
          border: solid 3px #7736fe;
          opacity: 0.9;
          display: flex;
          align-items: center;
          justify-items: center;
          // padding: 2vw;
          padding-left: 8px;
          height: 100%;
          img {
            width: 26px;
            height: 18px;
          }
        }
      }
      .right {
        width: 82%;
        display: flex;
        align-items: center;
        background-color: rgba(43, 43, 43, 0.5);
        border-radius: 36px 0 0 0;
        padding: 8px 56px 8px 8px;
        text-align: center;

        .request {
          background-color: #5c65cb;
          border-radius: 32px 2px 2px 2px;
          border: solid 2px #261e36;
          margin-right: 8px;
          width: 112px;
          font-size: 22px;
          color: #2b2b2b;
          padding: 2px 0;
        }
        .add {
          background-color: #8ff9f6;
          border-radius: 3px 3px 15px 3px;
          border: solid 2px #261e36;
          width: 120px;
          font-size: 24px;
          color: #2b2b2b;
          padding: 2px 0;
        }
      }
      // .right {
      //   width: 80%;
      //   height: 52px;
      //   display: flex;
      //   align-items: center;
      //   background-color: rgba(43, 43, 43, 0.5);
      //   border-radius: 34px 0 0 0;
      //   // border: 1vw solid #000000;
      //   // opacity: 0.5;
      //   // padding: 0vw 15vw 0vw 2vw;
      //   justify-content: center;

      //   font-family: 'KenyanCoffeeRg-BoldItalic';
      //   font-size: 30px;
      //   color: #928da3;
      //   .mar_ri_1 {
      //     margin-right: 10px;
      //   }
      //   // .request{
      //   // 	background-color: #8ff9f6;
      //   // 	border-radius: 8vw 1vw 1vw 1vw;
      //   // 	border: solid 1vw #261E36;
      //   // 	margin-right: 2vw;
      //   // 	width: 30vw;
      //   // 	font-size: 6vw;
      //   // 	color: #2b2b2b;
      //   // }
      //   // .add{
      //   // 	background-color: #5c65cb;
      //   // 	border-radius: 1vw 1vw 4vw 1vw;
      //   // 	border: solid 1vw #261E36;
      //   // 	width: 30vw;
      //   // 	font-size: 6vw;
      //   // 	color: #2b2b2b;
      //   // }
      // }
    }
  }
}
@media screen and (min-width: 480px) {
  //在这里写非小屏幕设备的样式  -- 非手机
  .friend {
    .btm {
      width: 430px;
    }
  }
}
</style>
